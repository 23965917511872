import "mapbox-gl/dist/mapbox-gl.css";

import { gql } from "@apollo/client";
import mapbox from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { FriendPlace } from "./friend-places";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapbox.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapbox.accessToken =
  "pk.eyJ1IjoiZ3ItLW9yIiwiYSI6ImNrank5cnpxaTB6eWsybmxzc2pzdjlpZWIifQ.JYZ-wIWW9RrB1NkxiHdP3w";

const LAYER_ID = "point";
const SOURCE_ID = "single-point";

function getBoundingBox(features) {
  const bounds = {};

  for (const feature of features) {
    const [lon, lat] = feature.center || feature.geometry.coordinates;
    bounds.xMin = bounds.xMin < lon ? bounds.xMin : lon;
    bounds.xMax = bounds.xMax > lon ? bounds.xMax : lon;
    bounds.yMin = bounds.yMin < lat ? bounds.yMin : lat;
    bounds.yMax = bounds.yMax > lat ? bounds.yMax : lat;
  }

  return bounds;
}

const fragments = {
  user: gql`
    fragment Map on users {
      friend_places {
        place {
          id
          coordinates
        }
        ...FriendPlace
      }
    }
    ${FriendPlace.fragments.friend_place}
  `,
};

export function Map({ user, selectedPlaceId, onSelectPlace }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const rootRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    const map = (mapRef.current = new mapbox.Map({
      container: rootRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 0.1,
    }));

    map.on("load", () => {
      map.addSource(SOURCE_ID, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      map.addLayer({
        id: LAYER_ID,
        source: SOURCE_ID,
        type: "circle",
        paint: {
          "circle-radius": 10,
          "circle-color": "#007cbf",
        },
      });

      map
        .on("click", LAYER_ID, (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { place_id, description } = e.features[0].properties;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapbox.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map)
            .on("close", () => onSelectPlace(null));

          onSelectPlace(place_id);
        })
        .on("mouseenter", LAYER_ID, () => {
          map.getCanvas().style.cursor = "pointer";
        })
        .on("mouseleave", LAYER_ID, () => {
          map.getCanvas().style.cursor = "";
        });

      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!isLoaded || !map) {
      return;
    }

    const source = map.getSource(SOURCE_ID);
    if (!source) {
      return;
    }
    const features = user.friend_places.map((friend_place) => ({
      type: "Feature",
      properties: {
        place_id: friend_place.place.id,
        description: ReactDOMServer.renderToStaticMarkup(
          <FriendPlace friend_place={friend_place} />
        ),
      },
      geometry: friend_place.place.coordinates,
    }));
    source.setData({
      type: "FeatureCollection",
      features,
    });
    const bb = getBoundingBox(features);
    map.fitBounds(
      [
        [bb.xMin, bb.yMin],
        [bb.xMax, bb.yMax],
      ],
      { animate: false, padding: 15 }
    );
  }, [isLoaded, user]);

  // useEffect(() => {
  //   const map = mapRef.current;
  //   if (!isLoaded || !map || !items.length) {
  //     return;
  //   }
  //
  //   const source = map.getSource(SOURCE_ID);
  //   if (!source) {
  //     return;
  //   }
  //   source.setData({
  //     type: "FeatureCollection",
  //     features: items,
  //   });
  //   const boundingBox = getBoundingBox(items);
  //   map.fitBounds(
  //     [
  //       [boundingBox.xMin, boundingBox.yMin],
  //       [boundingBox.xMax, boundingBox.yMax],
  //     ],
  //     { animate: false, padding: 15 }
  //   );
  // }, [isLoaded, items]);

  // useEffect(() => {
  //   const map = mapRef.current;
  //   const highlightedItem = items[highlightedIndex];
  //   if (!map || !highlightedItem) {
  //     return;
  //   }
  //   const { bbox, center } = highlightedItem;
  //   if (bbox) {
  //     map.fitBounds(bbox, {
  //       animate: false,
  //       padding: 15,
  //     });
  //   } else {
  //     map.jumpTo({ center, zoom: 15 });
  //   }
  // }, [items, highlightedIndex]);

  return <div ref={rootRef} style={{ height: 500, width: 500 }} />;
}

Map.fragments = fragments;
