import { gql, useMutation } from "@apollo/client";
import { useCombobox } from "downshift";
import mapbox from "mapbox-gl";
import React, { useRef, useState } from "react";

const fragments = {
  user: gql`
    fragment UserPlaces on users {
      id
      places {
        id
        name
        address
        coordinates
        comment
      }
    }
  `,
};

function PlaceSelect({ selectedItem, onSelectedItemChange }) {
  const abortControllerRef = useRef(null);
  const [inputItems, setInputItems] = useState([]);
  const {
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    isOpen,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => (item ? item.place_name : ""),
    onSelectedItemChange({ selectedItem }) {
      onSelectedItemChange(selectedItem);
    },
    async onInputValueChange({ inputValue }) {
      if (!inputValue) {
        selectItem(null);
        setInputItems([]);
        return;
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      try {
        const data = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            inputValue
          )}.json?access_token=${mapbox.accessToken}&autocomplete=true`,
          { signal: abortController.signal }
        ).then((res) => res.json());
        setInputItems(data.features);
        abortControllerRef.current = null;
      } catch (e) {
        if (e.name !== "AbortError") {
          throw e;
        }
      }
    },
  });

  return (
    <>
      <div {...getComboboxProps()}>
        <input {...getInputProps()} placeholder="Search for a place" />
        <button
          type="button"
          {...getToggleButtonProps()}
          aria-label={"toggle menu"}
        >
          &#8595;
        </button>
      </div>
      <ul {...getMenuProps()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.place_name}
            </li>
          ))}
      </ul>
    </>
  );
}

export function UserPlaces({ user }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  const mutationOptions = {
    refetchQueries: [
      {
        query: gql`
          query UserQuery {
            user {
              ...UserPlaces
            }
          }
          ${fragments.user}
        `,
      },
    ],
    awaitRefetchQueries: true,
  };

  const [insertPlace, insertPlaceResult] = useMutation(
    gql`
      mutation InsertPlaceMutation($input: places_insert_input!) {
        insert_places_one(object: $input) {
          id
          name
          address
          coordinates
        }
      }
    `,
    mutationOptions
  );

  const [deletePlace, deletePlaceResult] = useMutation(
    gql`
      mutation DeletePlaceMutation($id: Int!) {
        delete_places_by_pk(id: $id) {
          id
        }
      }
    `,
    mutationOptions
  );

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          insertPlace({
            variables: {
              input: {
                name,
                comment,
                address: selectedItem.place_name,
                coordinates: {
                  type: "Point",
                  coordinates: selectedItem.center,
                },
                user_id: user.id,
              },
            },
          });
        }}
      >
        <PlaceSelect
          selectedItem={selectedItem}
          onSelectedItemChange={setSelectedItem}
        />
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <textarea
          placeholder="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <button
          type="submit"
          disabled={
            insertPlaceResult.loading || !selectedItem || !name || !comment
          }
        >
          {selectedItem ? "Save" : "Search for a place to save"}
        </button>
      </form>
      {user.places.map((place) => (
        <div key={place.id}>
          {place.name} - {place.address}
          <button
            disabled={deletePlaceResult.loading}
            onClick={() => deletePlace({ variables: { id: place.id } })}
          >
            Delete
          </button>
        </div>
      ))}
    </>
  );
}

UserPlaces.fragments = fragments;
