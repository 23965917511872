import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React from "react";

import { FriendPlaces } from "./friend-places";

const FRIENDSHIP_FRAGMENT = gql`
  fragment Friendship on friendships {
    friend_request_id
    friend_user {
      id
      name
    }
    status
  }
`;

const SEARCH_QUERY = gql`
  query SearchUsers($name: String!) {
    friendships(where: { friend_user: { name: { _ilike: $name } } }) {
      ...Friendship
    }
  }
  ${FRIENDSHIP_FRAGMENT}
`;

const fragments = {
  user: gql`
    fragment FriendsUser on users {
      id

      friendships(where: { status: { _is_null: false } }) {
        ...Friendship
      }
    }
    ${FRIENDSHIP_FRAGMENT}
  `,
};

function Friendship({ user, friendship }) {
  const { friend_request_id, friend_user, status } = friendship;

  const mutationOptions = {
    refetchQueries: [
      {
        query: gql`
          query UserQuery {
            user {
              ...FriendsUser
              ...FriendPlaces
            }
          }
          ${fragments.user}
          ${FriendPlaces.fragments.user}
        `,
      },
      {
        query: gql`
          query FriendshipQuery($userId: Int!, $friendUserId: Int!) {
            friendships(
              where: {
                user_id: { _eq: $userId }
                friend_user_id: { _eq: $friendUserId }
              }
            ) {
              ...Friendship
            }
          }
          ${FRIENDSHIP_FRAGMENT}
        `,
        variables: {
          userId: user.id,
          friendUserId: friend_user.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  };
  const [deleteMutation, deleteMutationResult] = useMutation(
    gql`
      mutation UnfriendMutation($id: Int!) {
        delete_friend_requests_by_pk(id: $id) {
          id
        }
      }
    `,
    { variables: { id: friend_request_id }, ...mutationOptions }
  );
  const [mutate, mutationResult] = useMutation(
    status === "awaiting"
      ? gql`
          mutation AcceptFriendRequestMutation($id: Int!) {
            update_friend_requests_by_pk(
              pk_columns: { id: $id }
              _set: { is_accepted: true }
            ) {
              id
              is_accepted
            }
          }
        `
      : gql`
          mutation BefriendMutation($input: friend_requests_insert_input!) {
            insert_friend_requests_one(object: $input) {
              id
            }
          }
        `,
    mutationOptions
  );
  const [label, variables] = (() => {
    if (mutationResult.loading) {
      return ["Loading"];
    }

    if (!status) {
      return [
        "Befriend",
        {
          input: {
            requesting_user_id: user.id,
            user_id: friend_user.id,
          },
        },
      ];
    }

    switch (status) {
      case "awaiting":
        return ["Accept", { id: friend_request_id }];

      case "requested":
        return ["Awaiting"];

      default:
        return [];
    }
  })();

  return (
    <div>
      {friend_user.name}{" "}
      {label && (
        <button
          disabled={!variables}
          onClick={variables ? () => mutate({ variables }) : undefined}
        >
          {label}
        </button>
      )}
      {status !== null && (
        <button
          disabled={deleteMutationResult.loading}
          onClick={() => deleteMutation()}
        >
          {deleteMutationResult.loading ? "Unfriending..." : "Unfriend"}
        </button>
      )}
    </div>
  );
}

export function Friends({ user }) {
  const [search, { loading, data, error }] = useLazyQuery(SEARCH_QUERY);

  return (
    <>
      {error && error.message}

      {user.friendships.map((friendship, i) => (
        <Friendship key={i} {...{ user, friendship }} />
      ))}

      <input
        type="text"
        placeholder="find more"
        onKeyUp={(event) => {
          const { value } = event.target;
          if (!value) {
            return;
          }
          search({ variables: { name: `%${value}%` } });
        }}
      />
      {loading && (
        <div>
          <em>Loading...</em>
        </div>
      )}
      {data &&
        data.friendships.map((friendship, i) => (
          <Friendship key={i} {...{ user, friendship }} />
        ))}
      {data && data.friendships.length === 0 && (
        <div>
          <em>Nada</em>
        </div>
      )}
    </>
  );
}

Friends.fragments = fragments;
