import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";

export const AppContext = React.createContext({ isAuthenticated: false });

export function AppContextProvider({ children }) {
  const [token, setToken] = useState(null);
  const auth = useAuth0();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }
    auth.getAccessTokenSilently().then((token) => {
      setToken(token);
    });
  }, [auth]);

  const client = useMemo(() => {
    const authLink = setContext((_, { headers }) => {
      if (!token) {
        return { headers };
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(
        createHttpLink({ uri: "https://highlights.hasura.app/v1/graphql" })
      ),
      cache: new InMemoryCache({
        typePolicies: {
          friendships: {
            keyFields: ["friend_user", ["id"]],
          },
        },
      }),
    });
  }, [token]);

  const contextValue = useMemo(() => ({ isAuthenticated: !!token }), [token]);

  return (
    <AppContext.Provider value={contextValue}>
      <ApolloProvider {...{ client, children }} />
    </AppContext.Provider>
  );
}
