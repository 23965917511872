import { gql } from "@apollo/client";
import React, { useState } from "react";

import { FriendPlaces } from "./friend-places";
import { Map } from "./map";

const fragments = {
  user: gql`
    fragment Home on users {
      ...FriendPlaces
      ...Map
    }
    ${FriendPlaces.fragments.user}
    ${Map.fragments.user}
  `,
};

export function Home({ user }) {
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <FriendPlaces
        user={user}
        selectedPlaceId={selectedPlaceId}
        onSelectPlace={setSelectedPlaceId}
      />
      <Map
        user={user}
        selectedPlaceId={selectedPlaceId}
        onSelectPlace={setSelectedPlaceId}
      />
    </div>
  );
}

Home.fragments = fragments;
