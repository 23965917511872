import { Auth0Provider } from "@auth0/auth0-react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { App } from "./app";
import { AppContextProvider } from "./app-context";

ReactDOM.unstable_createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Auth0Provider
      domain="furfm.eu.auth0.com"
      clientId="MfC9TY6CMX8WptzDOiFP5H8s59k0ooxV"
      redirectUri={window.location.origin}
      audience="hasura.highlights"
      cacheLocation="localstorage"
    >
      <AppContextProvider>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </AppContextProvider>
    </Auth0Provider>
  </React.StrictMode>
);
