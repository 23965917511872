import { gql } from "@apollo/client";
import React from "react";

export const FriendPlace = ({
  friend_place: { place, friend_user },
  style,
  isSelected,
}) => (
  <div style={{ ...style, ...(isSelected ? { fontWeight: "bold" } : {}) }}>
    "{place.comment}" -{friend_user.name}
    <br />
    {place.name}
    <br />
    {place.address}
  </div>
);

FriendPlace.fragments = {
  friend_place: gql`
    fragment FriendPlace on friend_places {
      friend_user {
        name
      }
      place {
        comment
        name
        address
      }
    }
  `,
};

const fragments = {
  user: gql`
    fragment FriendPlaces on users {
      friend_places {
        place {
          id
        }
        ...FriendPlace
      }
    }
    ${FriendPlace.fragments.friend_place}
  `,
};

export const FriendPlaces = ({ user, selectedPlaceId, onSelectPlace }) => (
  <div>
    {user.friend_places.map((friend_place, i) => {
      const id = friend_place.place.id;
      return (
        <FriendPlace
          key={i}
          style={{ marginBottom: 10 }}
          {...{ friend_place }}
          isSelected={id === selectedPlaceId}
          onSelect={() => onSelectPlace(id)}
        />
      );
    })}
  </div>
);

FriendPlaces.fragments = fragments;
