import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

import { UserPlaces } from "./user-places";

const fragments = {
  user: gql`
    fragment Profile on users {
      id
      name
      ...UserPlaces
    }
    ${UserPlaces.fragments.user}
  `,
};

const SEARCH_QUERY = gql`
  query SearchUserNames($name: String!) {
    users(where: { name: { _eq: $name } }) {
      id
    }
  }
`;

export const Profile = ({ user }) => {
  const [name, setName] = useState(user.name);
  const nameTrim = name.trim();
  const hasNewName = name && nameTrim !== user.name;

  const [search, { loading, data }] = useLazyQuery(SEARCH_QUERY);

  const isNameTaken = data && data.users.length > 0;

  const [updateName] = useMutation(
    gql`
      mutation UpdateUserNameMutation($id: Int!, $name: String!) {
        update_users_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
          id
          name
        }
      }
    `
  );

  useEffect(() => {
    if (hasNewName) {
      search({ variables: { name: nameTrim } });
    }
  }, [hasNewName, nameTrim]);

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateName({ variables: { id: user.id, name: nameTrim } }).catch(
            (error) => {
              if (error.message.includes("unique_name")) {
              }
            }
          );
        }}
      >
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </label>
        {isNameTaken && "already taken"}
        <button type="submit" disabled={!hasNewName || loading || isNameTaken}>
          Save
        </button>
      </form>
      <UserPlaces user={user} />
    </>
  );
};

Profile.fragments = fragments;
