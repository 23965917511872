import { gql, useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { AppContext } from "./app-context";
import { Friends } from "./friends";
import { Home } from "./home";
import { Profile } from "./profile";

const APP_QUERY = gql`
  query AppQuery(
    $withHome: Boolean!
    $withFriends: Boolean!
    $withProfile: Boolean!
  ) {
    user {
      id
      name

      ...Home @include(if: $withHome)
      ...FriendsUser @include(if: $withFriends)
      ...Profile @include(if: $withProfile)
    }
  }

  ${Home.fragments.user}
  ${Friends.fragments.user}
  ${Profile.fragments.user}
`;

const equals = (x, y) => x && y && Object.keys(x).every((k) => x[k] === y[k]);

export function App() {
  const { isAuthenticated } = useContext(AppContext);
  const auth = useAuth0();
  const [fetchData, { called, loading, data, error, variables }] = useLazyQuery(
    APP_QUERY
  );
  const [user] = data ? data.user : [];
  const [activePage, setActivePage] = useState("home");

  const targetVars = useMemo(
    () =>
      Object.fromEntries(
        [
          ["withHome", "home"],
          ["withFriends", "friends"],
          ["withProfile", "profile"],
        ].map(([key, page]) => [key, page === activePage])
      ),
    [activePage]
  );

  useEffect(() => {
    if (isAuthenticated && !equals(variables, targetVars)) {
      fetchData({
        variables: targetVars,
      });
    }
  }, [variables, isAuthenticated, fetchData, targetVars]);

  const Main = {
    home: Home,
    friends: Friends,
    profile: Profile,
  }[activePage];

  return (
    <div>
      {error && error.message}

      {["home", "friends", "profile"].map((page) => (
        <button
          key={page}
          disabled={page === activePage}
          onClick={() => setActivePage(page)}
        >
          {page}
        </button>
      ))}

      {auth.isAuthenticated && user ? (
        <button onClick={auth.logout}>Log out ({user.name})</button>
      ) : (
        !auth.isLoading &&
        !loading && <button onClick={auth.loginWithRedirect}>Log in</button>
      )}

      <main>
        {auth.isLoading ||
        (called && loading) ||
        !equals(variables, targetVars) ? (
          "Loading..."
        ) : (
          <Main user={user} />
        )}
      </main>
    </div>
  );
}
